
export default {
  props: {
    type: {
      default: 'text'
    },
    pattern: {
      default: ''
    },
    value: {},
    label: {
      default: ''
    },
    styleField: {
      default: ''
    },
    placeholder: {
      default: ''
    },
    disabled: {
      default: false
    },
    inline: {
      default: false
    },
    reset: {
      default: false
    },
    uppercase: {
      default: false
    },
    onlyNumbers: {
      default: false
    },
    prepend: {
      default: false
    },
    prependIcon: {
      default: false
    },
    dense: {
      default: false
    },
    error: {
      default: false
    },
    ref: {
      default: ''
    },
    name: {
      default: ''
    }
  },
  methods: {
    update() {
      if (this.uppercase) {
        if (typeof this.valueLocal !== 'undefined' && this.valueLocal !== null && typeof this.valueLocal.toUpperCase !== 'undefined') {
          this.valueLocal = this.valueLocal.toUpperCase();
        }
      }
      if (this.onlyNumbers) {
        if (typeof this.valueLocal !== 'undefined' && this.valueLocal !== null && typeof this.valueLocal.replace !== 'undefined') {
          const regex = /[^\d]/g;
          const sanitizedValue = this.valueLocal.replace(regex, '');
          this.valueLocal = sanitizedValue;
        }
      }
      this.$refs.input.focus();
    }
  },
  computed: {
    valueLocal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  }
}
